import styled from "astroturf/index"
import React from "react"

export const WidthContainer = ({ children }) => (
  <OuterContainer>
    <InnerContainer>{children}</InnerContainer>
  </OuterContainer>
)

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  position: relative;
`

export const InnerContainer = styled.div`
  box-sizing: border-box;
  width: 100%;

  @media screen and (min-width: 1600px) {
    max-width: 1440px;
  }

  @media screen and (max-width: 1599px) {
    max-width: 1200px;
  }

  @media screen and (max-width: 1359px) {
    max-width: 964px;
  }

  @media screen and (max-width: 1024px) {
    max-width: none;
    padding: 0 30px;
  }
`
