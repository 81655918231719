import styled from "astroturf"
import React from "react"
import { IconPhone } from "../../icon/iconHeader/iconPhone"
import { IconMail } from "../../icon/iconHeader/iconMail"
import { IconFacebook } from "../../icon/iconHeader/iconFacebook"
import { IconInstagram } from "../../icon/iconHeader/iconInstagram"
import { IconLinkedin } from "../../icon/iconHeader/iconLinkedin"
import { IconVk } from "../../icon/iconHeader/iconVk"
import { IconSkype } from "../../icon/iconHeader/iconSkype"
import { IconTelegram } from "../../icon/iconHeader/iconTelegram"

export const SubHeader = ({ sticky }) => {
  return (
    <Container sticky={sticky}>
      <ContainerContent className="container">
        <LeftBlock>
          <Link href="tel:+74993481107">
            <IconPhone />
            +7 499 348 1107
          </Link>

          <Link href="mailto:office@dex-it.ru">
            <IconMail />
            office@dex-it.ru
          </Link>
        </LeftBlock>
        <RightBlock>
          <SocialLink
            i={"facebook"}
            target="_blank"
            href="http://www.facebook.com/dextechnology"
            rel="noopener noreferrer"
            aria-label={"facebook"}
          >
            <IconFacebook />
          </SocialLink>
          <SocialLink
            i={"instagram"}
            target="_blank"
            href="https://www.instagram.com/dex.it/"
            rel="noopener noreferrer"
            aria-label={"instagram"}
          >
            <IconInstagram />
          </SocialLink>
          <SocialLink
            i={"linkedin"}
            target="_blank"
            href="http://www.linkedin.com/company/dextechnology"
            rel="noopener noreferrer"
            aria-label={"linkedin"}
          >
            <IconLinkedin />
          </SocialLink>
          <SocialLink
            i={"vk"}
            target="_blank"
            href="https://vk.com/dextechnology"
            aria-label={"vk"}
            rel="noopener noreferrer"
          >
            <IconVk />
          </SocialLink>
          <SocialLink
            i={"skype"}
            target="_blank"
            href="skype:vvvcsq3?call"
            rel="noopener noreferrer"
            aria-label={"skype"}
          >
            <IconSkype />
          </SocialLink>
          <SocialLink
            i={"telegram"}
            target="_blank"
            href="https://t.me/Dexitru"
            rel="noopener noreferrer"
            aria-label={"telegram"}
          >
            <IconTelegram />
          </SocialLink>
        </RightBlock>
      </ContainerContent>
    </Container>
  )
}

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.66);
  height: 40px;
  line-height: 40px;
  transition: all 0.3s ease-in-out;

  &.sticky {
    /* line-height: 0; */
    height: 0;
    opacity: 0;
    overflow: hidden;
  }

  @media (max-width: 767px) {
    display: none;
  }
`
const ContainerContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LeftBlock = styled.div`
  display: flex;
`
const RightBlock = styled.div`
  display: flex;
`

const Link = styled.a`
  font-size: 14px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.66);
  fill: rgba(255, 255, 255, 0.33);
  cursor: pointer;
  margin-right: 1.4rem;

  svg {
    margin-right: 8px;
  }

  &:hover {
    color: #ffffff;
  }
  &:active,
  &:hover {
    text-decoration: none;
  }
`
export const SocialLink = styled.a`
  border-radius: 50%;
  background: inherit;
  cursor: pointer;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  opacity: 0.66;

  &.i-facebook {
    &:hover {
      opacity: 1;
      background-color: #42599e;
    }
  }

  &.i-instagram {
    &:hover {
      opacity: 1;
      background: radial-gradient(circle farthest-corner at 35% 100%, #fec564, transparent 50%),
        radial-gradient(circle farthest-corner at 10% 140%, #feda7e, transparent 50%),
        radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
        radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
        radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
        radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
        radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
        linear-gradient(#6559ca, #bc318f 30%, #e42e66 50%, #fa5332 70%, #ffdc80 100%);
    }
  }
  &.i-linkedin {
    &:hover {
      opacity: 1;
      background-color: #117eb9;
    }
  }
  &.i-vk {
    &:hover {
      opacity: 1;
      background-color: #4c75a3;
    }
  }
  &.i-skype {
    &:hover {
      opacity: 1;
      background-color: #00aff0;
    }
  }
  &.i-telegram {
    &:hover {
      opacity: 1;
      background-color: #4c75a3;
    }
  }
`
