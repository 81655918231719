import React from "react"
import { Helmet } from "react-helmet"

export const SEO = ({ lang, meta, title, description }) => {
  const metaDescription = description

  return (
    <Helmet
      htmlAttributes={{
        lang:"ru",
      }}
      title={title}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        // {
        //   name: `twitter:creator`,
        //   content: site.siteMetadata.author,
        // },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `yandex-verification`,
          content: `642e78ff18ea1f11`,
        },
      ].concat(meta || [])}
    >
      <script defer>
        {`(function (d, w, c) { (w[c] = w[c] || []).push(function() { try { w.yaCounter44277909 = new Ya.Metrika2({ id:44277909, clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true, trackHash:true }); } catch(e) { } }); var n = d.getElementsByTagName("script")[0], s = d.createElement("script"), f = function () { n.parentNode.insertBefore(s, n); }; s.type = "text/javascript"; s.async = true; s.src = "https://mc.yandex.ru/metrika/tag.js"; if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); } })(document, window, "yandex_metrika_callbacks2");`}
      </script>
      <noscript>{`<div><img src="https://mc.yandex.ru/watch/44277909" style="position:absolute; left:-9999px;" alt="" /></div>`}</noscript>
      <script defer>
        {`(function(){ var widget_id = 'mMJtI5iPHo';var d=document;var w=window;function l(){
        var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = '//code.jivosite.com/script/widget/'+widget_id; var ss = document.getElementsByTagName('script')[0]; ss.parentNode.insertBefore(s, ss);}if(d.readyState=='complete'){l();}else{if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}
      </script>
    </Helmet>
  )
}
