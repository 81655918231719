import styled from "astroturf"
import React from "react"
import { EMAIL, PHONE } from "../../constants/contacts"
import { WidthContainer } from "../../ui/WidthContainer"
import { Socials } from "./Socials"
import logoDark from "../../images/logo-dark.svg"

export const Footer = () => {
  return (
    <ColorContainer id={"writeToUs"}>
      <WidthContainer>
        <Container>
          <Logo src={logoDark} alt={"logo"}/>
          <Contacts>
            <Text href={EMAIL.link}>{EMAIL.title}</Text>
            <Text href={PHONE.link}>{PHONE.title}</Text>
          </Contacts>
          <SocialsContainerDesktop>
            <Socials />
          </SocialsContainerDesktop>
        </Container>
        <SocialsContainerMobile>
          <Socials />
        </SocialsContainerMobile>
      </WidthContainer>
    </ColorContainer>
  )
}

const ColorContainer = styled.footer`
  background-color: #ffffff;
  padding: 40px 0 42px;
  @media screen and (max-width: 1199px) {
    padding: 30px 0;
  }
`

const Container = styled.footer`
  display: flex;
  align-items: flex-end;
  @media screen and (max-width: 1199px) {
    align-items: center;
  }
`

const Logo = styled.img`
  width: 93px;
  @media screen and (max-width: 1199px) {
    width: 77px;
  }
`

const Contacts = styled.div`
  display: flex;
  align-items: center;
  margin-left: 69px;
  & > :not(:first-child) {
    margin-left: 79px;
  }

  @media screen and (max-width: 1199px) {
    flex-direction: column;
    margin-left: auto;
    align-items: flex-start;
    & > :not(:first-child) {
      margin-left: 0;
      margin-top: 10px;
    }
  }
`

const Text = styled.a`
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0.04em;
  color: #121e30;
  text-decoration: none;
  @media screen and (max-width: 1199px) {
    font-size: 18px;
    line-height: 21px;
  }
`

const SocialsContainerDesktop = styled.div`
  margin-left: auto;
  @media screen and (max-width: 1199px) {
    display: none;
  }
`

const SocialsContainerMobile = styled.div`
  margin-top: 30px;
  @media screen and (min-width: 1200px) {
    display: none;
  }
`
