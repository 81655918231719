export const messageTypes = {
  mobile: {
    key: "mobile",
    label: "Mobile",
  },
  web: {
    key: "web",
    label: "Web",
  },
  backend: {
    key: "backend",
    label: "Backend",
  },
  design: {
    key: "design",
    label: "Дизайн",
  },
  analitics: {
    key: "analitics",
    label: "Аналитика",
  },
  test: {
    key: "test",
    label: "Тестирование",
  },
}
