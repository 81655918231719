import styled from "astroturf"
import React, { useState } from "react"

export const Input = ({ placeholder, onChange, value }) => {
  const [open, setOpen] = useState(false)

  const changeInput = event => {
    onChange(event)
  }


  return (
    <Label>
      <span className={open || value ? "active" : ""}>{placeholder}</span>
      <InputText onChange={changeInput} value={value} onFocus={() => setOpen(true)} onBlur={() => setOpen(false)} />
    </Label>
  )
}

const InputText = styled.input`
  font-size: 24px;
  color: #ffffff;
  background: inherit;
  border: none;
  outline: none;
  border-bottom: 1px solid #ffffff;
  width: 100%;
  padding: 6px 0 18px;
  font-weight: 500;

  @media (max-width: 420px) {
    font-size: 18px;
    padding: 6px 0 8px;
  }
`

const Label = styled.label`
  font-size: 24px;
  color: #ffffff;
  cursor: auto;
  position: relative;
  width: 100%;
  font-weight: 500;
  margin-top: 10px;

  span {
    position: absolute;
    top: 0;
    transition: all 0.2s ease-in-out;
  }

  :global(.active) {
    top: -30px;
    font-size: 18px;
  }

  @media (max-width: 420px) {
    font-size: 18px;
  }
`
